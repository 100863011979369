import React from 'react';
import config from '../../config';

export default function PageFooter() {
  return (
    <footer id="footer">
      <ul className="copyright">
        <li>&copy; {config.authorName}</li>
        <li><a href="https://www.underdoginvestor.com">The Underdog Investor</a></li>
        <li><a href="https://www.dfwdevs.com/">DFW DEVS</a></li>
        <li><a href="https://www.dfwinvestmentproperties.com">DFW Investment Properties</a></li>
        <li><a href="https://www.webuyanyhouseindfw.com">We Buy Any House In DFW</a></li>
      </ul>
    </footer>
  );
}
