import React from 'react';

import config from '../../config';
const pic = require('../assets/images/thumb-profile.jpg');

export default function Footer() {
  return (
    <header>
      <span className="avatar">
        <img height="128px" src={pic} alt="" />
      </span>
      <h1>{config.authorName}</h1>
      <div className="headings">
        {
          config.headings.map(heading => (
            <p>{heading}</p>
          ))
        }
      </div>
    </header>
  );
}
