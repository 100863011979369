module.exports = {
  siteTitle: 'Alfredo Goytia', // <title>
  manifestName: 'Identity',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  authorName: 'Alfredo Goytia',
  headings: ['Real Estate Investor', 'Entrepreneur', 'Software Engineer'],
  // social
  socialLinks: [
    {
      icon: 'fa-phone',
      name: 'Phone',
      url: 'tel:+18177108733'
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:fredo@dfwinvestmentproperties.com'
    },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://www.facebook.com/fredofyre/'
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/fredofyre'
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/fredofyre/'
    },
    {
      icon: 'fa-linkedin',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/fredo-g/'
    },
    {
      icon: 'fa-male',
      name: 'Investor',
      url: 'https://www.dfwdevs.com/'
    },
    {
      icon: 'fa-youtube',
      name: 'YouTube',
      url: 'https://www.youtube.com/@fredofyre'
    }
  ],
};
